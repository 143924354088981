// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/arco.regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --orange: #FC743A;
  --green: #20C954;
  --color-home:#FF8855;
  --color-about:#FE9F8B;
  --color-presale:#FF8855;
  --color-tokenomics:#DBFE8B;
  --color-community:#8BFEDD;
  --color-contact:#8BFEDD;
  --color-faq:#FF8855;
  --color-black:#000000;
  --font: 'Roboto Condensed';
  --font-arco: 'arco';
}

@font-face {
  font-family: 'arco';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  box-sizing: border-box;
}

html,
body{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;EACnB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,+DAA8D;EAC9D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,2CAA2C;EAC3C,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[":root {\n    --orange: #FC743A;\n  --green: #20C954;\n  --color-home:#FF8855;\n  --color-about:#FE9F8B;\n  --color-presale:#FF8855;\n  --color-tokenomics:#DBFE8B;\n  --color-community:#8BFEDD;\n  --color-contact:#8BFEDD;\n  --color-faq:#FF8855;\n  --color-black:#000000;\n  --font: 'Roboto Condensed';\n  --font-arco: 'arco';\n}\n\n@font-face {\n  font-family: 'arco';\n  src: url('./assets/fonts/arco.regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n*{\n  box-sizing: border-box;\n}\n\nhtml,\nbody{\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.roboto-condensed {\n  font-family: \"Roboto Condensed\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 500;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
