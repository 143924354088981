import React, {useState} from 'react';
import {HeaderS, NavItem, Navigate, Logo, ButtonBay, HeaderMob, BtnMenu} from './styled';
import {navigateConfig} from './config';
import {useWindowSize} from '../../hooks';

const logo = require('../../assets/images/logo.png');
const menu = require('../../assets/images/menu.png');

export const Header = () => {
    const {width} = useWindowSize();
    const [show, setShow] = useState<boolean>(false)
    if (width <= 1023) {
        return (
            <HeaderMob>
                <Logo src={logo} alt={'logo'}/>
                <ButtonBay>BUY NOW!</ButtonBay>
                <BtnMenu imageUrl={menu} onClick={() => setShow(!show)} />
                {show ? <Navigate>
                    {navigateConfig.map(item => <NavItem key={item.name} bgColor={item.bg}>{item.name}</NavItem>)}
                </Navigate> : null}
            </HeaderMob>
        );
    }

    return (
        <HeaderS>
            <Logo src={logo} alt={'logo'}/>
            <Navigate>
                {navigateConfig.map(item => <NavItem key={item.name} bgColor={item.bg}>{item.name}</NavItem>)}
            </Navigate>
            <ButtonBay>BUY NOW!</ButtonBay>
        </HeaderS>
    );
};
