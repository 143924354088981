import React, { useEffect, useState } from 'react';
import { JsonRpcProvider } from 'ethers';
import { Wrapper, Container, Title, SubTitle, TitleWrap } from './styled';
import { Header } from './components/header';
import { Row, Column } from './utils';
import { Form } from './components/form';
import { InfoHome } from './components/infoHome';
import { useWindowSize } from './hooks';

const bg = require('./assets/images/first-bg.png');
const bgMob = require('./assets/images/first-bg-mob.png');

export const App = () => {
    const { width } = useWindowSize();
    const [provider, setProvider] = useState<JsonRpcProvider | null>(null);

    // Подключение к Infura
    useEffect(() => {
        const initializeProvider = async () => {
            const infuraProvider = new JsonRpcProvider(
                'https://sepolia.infura.io/v3/f92deb2834c147cc864a0b47ce6ffed3'
            );
            setProvider(infuraProvider);
        };
        initializeProvider();
    }, []);

    return (
        <Wrapper>
            <Container imageUrl={width >= 1024 ? bg : bgMob}>
                <Header />
                <Row gap="5px">
                    <Column direction={'column'} size={8} sizeSm={12}>
                        <TitleWrap>
                            <Title>
                                <span>The</span>
                                <span>Future</span>
                                <span>of</span>
                                <span>Finance</span>
                            </Title>
                            <SubTitle>is Decentralized</SubTitle>
                        </TitleWrap>
                        <InfoHome />
                    </Column>
                    <Column alignCenter={true} size={4} sizeSm={12}>
                        <Form provider={provider} />
                    </Column>
                </Row>
            </Container>
        </Wrapper>
    );
};
