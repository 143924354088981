import styled from 'styled-components';

export const InfoCard = styled.div`
  max-width: 438px;
  width: 100%;
  height: 294px;
  border: 1px solid #000000;
  box-shadow: 0 2px 2px #000000;
  border-radius: 24px;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  
  @media(max-width: 1024px){
    margin-top: 400px;
  }
`

export const InfoText = styled.p`
  margin: 0 0 10px;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font); 
  text-align: center;

  @media(max-width: 1024px){
    font-size: 18px;
    margin: 0;
  }
`

export const InfoButton = styled.div.withConfig({
    shouldForwardProp: prop => !['imageUrl'].includes(prop)
})<{imageUrl: string}>`
  width: 100%;
  height: 62px;
  padding: 10px 20px;
  background-image: url(${({imageUrl}) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: 0.3s;
  transform: scale(1);
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font);
  color: #ffffff;
  border-radius: 12px;
  text-transform: uppercase;
  
  & svg {
    margin-right: 10px;
  }
  
  &:active{
    transition: 0.3s;
    transform: scale(0.98);
    box-shadow: 3px 3px 6px #000000;
  }
  
  &:hover{
    cursor: pointer;
  }
  
  @media(max-width: 1024px){
    font-size: 16px;
  }
`;
