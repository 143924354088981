import React, { useState, useEffect } from 'react';
import { ethers, Contract, getAddress, parseEther, formatUnits } from 'ethers';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import presaleAbi from '../../abi/presaleABI.json';

import {
    FormS,
    FormTitle,
    CountDown,
    CountDownItem,
    CountDownSeparator,
    CountDownItemSubtext,
    StyledProgress,
    Text16,
    Text24,
    TextBox,
    Box,
    BoxItem,
    Input,
    InputText
} from './styled';
import { InfoButton } from '../infoHome/styled';

const bg = require('../../assets/images/form-bg.png');
const ethBg = require('../../assets/images/btn-etc.png');
const walletBg = require('../../assets/images/btn-wallet.png');

// Замените на ваш реальный Infura endpoint (Sepolia пример):
const INFURA_URL = 'https://sepolia.infura.io/v3/f92deb2834c147cc864a0b47ce6ffed3';
// Замените адрес на корректный адрес вашего пресейла
const proxyAddress = getAddress("0x6548799B3F11A935AAcFbfE49d03Be1E77f2C2a8");

const erc20MetadataAbi = [
    "function decimals() view returns (uint8)"
];

export const Form = () => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const [currentStage, setCurrentStage] = useState<number>(0);
    const [currentPriceRaw, setCurrentPriceRaw] = useState<bigint | null>(null);
    const [fundsRaised, setFundsRaised] = useState<bigint | null>(null);
    const [tokensSold, setTokensSold] = useState<bigint | null>(null);
    const [remainingTime, setRemainingTime] = useState<number | null>(null);

    const [userPurchased, setUserPurchased] = useState<number>(0);

    const [ethAmount, setEthAmount] = useState<string>('');
    const [gmfAmount, setGmfAmount] = useState<string>('');

    const connectWallet = async () => {
        if ((window as any).ethereum) {
            try {
                const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                }
            } catch (error) {
                console.error('User rejected the connection:', error);
            }
        } else {
            alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        }
    };

    useEffect(() => {
        if ((window as any).ethereum) {
            (window as any).ethereum.request({ method: 'eth_accounts' }).then((accounts: string[]) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                }
            });
        }
    }, []);

    const fetchData = async () => {
        setErrorMsg(null);
        try {
            // Подключаемся к инфуре для чтения данных
            const provider = new ethers.JsonRpcProvider(INFURA_URL);
            const contract = new Contract(proxyAddress, presaleAbi, provider);

            console.log("Calling getPresaleInfo()...");
            const [cStage, cPrice, fRaised, tSold, rTime] = await contract.getPresaleInfo();
            console.log("getPresaleInfo returned:", {
                cStage: cStage.toString(),
                cPrice: cPrice.toString(),
                fRaised: fRaised.toString(),
                tSold: tSold.toString(),
                rTime: rTime.toString()
            });

            const stageNum = Number(cStage);
            const priceNum = BigInt(cPrice.toString());
            const raisedNum = BigInt(fRaised.toString());
            const soldNum = BigInt(tSold.toString());
            const timeNum = Number(rTime);

            setCurrentStage(stageNum);
            setCurrentPriceRaw(priceNum);
            setFundsRaised(raisedNum);
            setTokensSold(soldNum);
            setRemainingTime(timeNum);

            if (stageNum === 0 && priceNum === BigInt(0)) {
                setErrorMsg('Presale not initialized. Owner must call initialize() or restartPresale().');
            }

            // Если кошелёк подключён, попробуем получить userPurchased:
            if (walletAddress) {
                try {
                    // Для вызова pendingClaims и tokenAddress можно использовать Infura (чтение)
                    const pending = await contract.pendingClaims(walletAddress);
                    const tokenAddress = await contract.tokenAddress();

                    if (tokenAddress === ethers.ZeroAddress) {
                        console.warn('Token address is zero. Cannot fetch token decimals.');
                        setErrorMsg('Token address not set in presale contract. Unable to determine purchased tokens.');
                    } else {
                        // Получаем decimals через инфуру (чтение)
                        const tokenContract = new Contract(tokenAddress, erc20MetadataAbi, provider);
                        const tokenDecimals = await tokenContract.decimals();
                        const pendingAmount = Number(formatUnits(pending, tokenDecimals));
                        console.log(`User ${walletAddress} purchased:`, pendingAmount, "GMF");
                        setUserPurchased(pendingAmount);
                    }
                } catch (err) {
                    console.warn('Error fetching user data:', err);
                    if (!errorMsg) {
                        setErrorMsg('Failed to fetch user token data. Check if the token is a valid ERC-20.');
                    }
                }
            }

        } catch (error: any) {
            console.error('Error fetching contract data:', error);
            if (!errorMsg) {
                setErrorMsg('Error fetching data. Possibly contract not initialized or wrong network. Check presale status and token address.');
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [walletAddress]);

    const handleBuyTokens = async () => {
        setErrorMsg(null);
        if (!ethAmount || Number(ethAmount) <= 0) {
            alert("Enter a valid ETH amount first.");
            return;
        }

        if (!walletAddress) {
            alert("Connect your wallet first.");
            return;
        }

        try {
            // Для покупки используем MetaMask (BrowserProvider)
            const provider = new ethers.BrowserProvider((window as any).ethereum);
            const signer = await provider.getSigner();
            const contract = new Contract(proxyAddress, presaleAbi, signer);

            const tx = await contract.buyTokens({
                value: parseEther(ethAmount),
            });

            await tx.wait();
            alert('Tokens purchased successfully!');
            fetchData();
        } catch (error: any) {
            console.error('Error buying tokens:', error);
            if (error.reason && error.reason.includes("Presale not active")) {
                setErrorMsg('Presale not active. Check start/end times and status.');
            } else {
                setErrorMsg('Error buying tokens. Possibly presale not active or invalid token/price.');
            }
        }
    };

    const updateAmounts = (value: string, isEth: boolean) => {
        if (!/^\d*\.?\d*$/.test(value)) {
            return;
        }

        setErrorMsg(null);

        if (isEth) {
            setEthAmount(value);
        } else {
            setGmfAmount(value);
        }
    };

    const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Time is up!</span>;
        } else {
            return (
                <CountDown>
                    <div>
                        <CountDownItem>{days}</CountDownItem>
                        <CountDownItemSubtext>Days</CountDownItemSubtext>
                    </div>
                    <CountDownSeparator>:</CountDownSeparator>
                    <div>
                        <CountDownItem>{hours}</CountDownItem>
                        <CountDownItemSubtext>Hours</CountDownItemSubtext>
                    </div>
                    <CountDownSeparator>:</CountDownSeparator>
                    <div>
                        <CountDownItem>{minutes}</CountDownItem>
                        <CountDownItemSubtext>Minutes</CountDownItemSubtext>
                    </div>
                    <CountDownSeparator>:</CountDownSeparator>
                    <div>
                        <CountDownItem>{seconds}</CountDownItem>
                        <CountDownItemSubtext>Seconds</CountDownItemSubtext>
                    </div>
                </CountDown>
            );
        }
    };

    return (
        <FormS imageUrl={bg}>
            <FormTitle>Grab it now before the cost goes up!</FormTitle>
            {errorMsg && (
                <Text16 center={true} style={{ color: 'red', marginBottom: '10px' }}>
                    {errorMsg}
                </Text16>
            )}
            {remainingTime !== null && remainingTime > 0 ? (
                <>
                    <Countdown date={Date.now() + remainingTime * 1000} renderer={renderer} />
                    <Text16 center={true} style={{ margin: '10px 0 5px' }}>
                        Until Next Price Increase
                    </Text16>
                </>
            ) : (
                <Text16 center={true} style={{ margin: '10px 0 5px' }}>
                    No active countdown. Possibly presale not active or not initialized.
                </Text16>
            )}
            <StyledProgress value={0.7} />
            <TextBox>
                <Text16 center={true}>Funds Raised (WEI):</Text16>
                <Text16 center={true}>{fundsRaised ? fundsRaised.toString() : '0'}</Text16>
            </TextBox>
            <Text24 style={{ margin: '10px 0' }}>You purchased: {userPurchased.toFixed(3)} $GMF</Text24>
            <Text24 style={{ margin: '10px 0 20px' }}>
                Current Price Raw: {currentPriceRaw ? currentPriceRaw.toString() : '...'}
            </Text24>
            <InfoButton imageUrl={ethBg}>ETH</InfoButton>
            <Box>
                <BoxItem>
                    <Text16 center={false}>You Pay (ETH)</Text16>
                    <Input>
                        <input
                            type="number"
                            placeholder="Enter ETH amount"
                            value={ethAmount}
                            onChange={(e) => updateAmounts(e.target.value, true)}
                        />
                    </Input>
                </BoxItem>
                <BoxItem>
                    <Text16 center={false}>You Receive ($GMF)</Text16>
                    <Input>
                        <input
                            type="number"
                            placeholder="Enter GMF amount"
                            value={gmfAmount}
                            onChange={(e) => updateAmounts(e.target.value, false)}
                        />
                        <InputText>$GMF</InputText>
                    </Input>
                </BoxItem>
            </Box>
            <InfoButton
                imageUrl={walletBg}
                style={{ textTransform: 'none' }}
                onClick={walletAddress ? handleBuyTokens : connectWallet}
            >
                {walletAddress
                    ? `Buy Tokens: ${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`
                    : 'Connect Wallet'}
            </InfoButton>
        </FormS>
    );
};
