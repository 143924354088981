import styled from 'styled-components';

type ContainerProps = {
    imageUrl: string;
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media(max-width: 1024px){
    background-color: #FE9F8B;
  }
`;

export const Container = styled.div.withConfig({
    shouldForwardProp: prop => !['imageUrl'].includes(prop)
})<ContainerProps>`
  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  padding: 0 20px;
  background-image: url(${({imageUrl}) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  
  @media(max-width: 1024px){
    background-size: contain;
    background-position: top;
    padding: 0 5px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 0 10px;
  
  @media(max-width: 1024px){
    padding: 0;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-family: var(--font-arco);
  font-size: 64px;
  text-shadow: 2px 2px 0 black,
  -2px 2px 0 black,
    2px -2px 0 black,
    -2px -2px 0 black;

  & span {
    display: inline-block;
    margin: 0 5px;

    &:nth-child(1) {
      color: #ffffff;
    }

    &:nth-child(2) {
      color: var(--orange);
    }

    &:nth-child(3) {
      color: #ffffff;
    }

    &:nth-child(4) {
      color: var(--green);
    }
  }

  @media(max-width: 1024px){
    font-size: 32px;
  }
`;

export const SubTitle = styled.span`
  font-family: var(--font-arco);
  font-size: 64px;
  color: var(--color-black);
  text-shadow: 2px 2px 0 #ffffff,
  -2px 2px 0 #ffffff,
    2px -2px 0 #ffffff,
    -2px -2px 0 #ffffff;

  @media(max-width: 1024px){
    font-size: 32px;
  }
`;
